import React from 'react';
import automation from '../images/automation.jpg'
import './ServicesStyle.css';


function Automation() {
  return (
    <div className='container-fluid automation-container  services pb-3 pt-2'>
      <div className='row  text-center ' >
        <h3 className='heading mt-3'><u>Automation</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 services-text-container'>

        <div className='col-lg-6 services-text  col-sm-7'>
          <ul className='fs-5'>
            <li className='p-3'>
              QA Automation is a vital part of the software development process.
            </li>
            <li className='p-3'>
              It relies on specialized tools to quickly and effeciently test software
              and catch potential problems to help bring it to market faster while
              maintaining an excellent user experience.
            </li>

          </ul>
        </div>
        <div className='col-lg-6 automationImg col-sm-7'>
          <img src={automation} alt='automationimage' height="300" width="100%" className='shadow-light rounded-3' />
        </div>
      </div>
    </div>
  )
}

export default Automation
