import React from 'react'
import './Style.css'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='container-fluid d-flex flex-row justify-content-center  footer-container pb-2 p-2'>
      <div className='row d-flex justify-content-between '>
        <div className='About col-lg-4 text-start mt-4'>
          <h4 className='text-center fs-5'>About Us</h4>
          <div className=' text-center  ml-0'>
            <ul className='list-unstyled'>
            <Link to='/about' ><li style={{fontSize:14}}>About Us</li></Link>
            <Link to='/contact'><li style={{fontSize:14}}>Contact Us</li></Link>
            </ul>
          </div>

        </div>
        <div className='Address col-lg-4 mt-4 mb-2 text-center'>
          <h4 className='text-center fs-5'>Address</h4>
          <div className='d-flex flex-row justify-content-center mt-4'>
            <i className="fa-solid fa-location-dot ml-3"></i>
            <p className='ml-3 text-center'>Vasista Bhavan-1st floor,
              opposiite lane to DLF Cyber City
              APHB Colony,Indira Nagar,
              GachiBowli, Hyderabad
              Telangana:- 500032
            </p>
          </div>
        </div>
        <div className='Contact col-lg-4 mt-4 text-center  ml-0 pb-5'>
          <h4 className='text-center fs-5'>Contact Us</h4>
          <div className='d-flex flex-row justify-content-center mt-4'>
            <i className="fa-solid fa-phone"></i>
            <p className='ml-3' style={{fontSize:14}}>
              +91-4049515740
            </p>
          </div>
          <div className='d-flex flex-row justify-content-center'>
            <i className="fa-solid fa-envelope"></i>
            <p className='ml-3 mb-5 pb-4' style={{fontSize:14}}>
              info@wamikatech.com
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
