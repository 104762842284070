import React from 'react';
import bigcommerce from '../images/bigcommerce.webp'

function BigCommerce() {
  return (
    <div className='container-fluid Bigcommerce-container technologies pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>BigCommerce</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 technologies-text'>

        <div className='col-lg-6 BigCommerce '>
          <ul className='fs-5'>
            <li className='p-3'>
              BigCommerce is a NASDAQ-listed ecommerce platform that provide software as a services to retailers.
            </li>
            <li className='p-3'>
              The company's platform includes online store creation, search engine optimization, hosting, and mrketing and security from small to Enterprise sized businesses.
            </li>
          </ul>
        </div>
        <div className='col-lg-6 BCImg col-sm-7 '>
          <img src={bigcommerce} alt='BCimage' height="350" width="100%" className='shadow rounded-3 ' />
        </div>
      </div>
    </div>
  )
}

export default BigCommerce
