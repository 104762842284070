import React from 'react';
import devops from '../images/devops.png'
import './ServicesStyle.css';


function Devops() {
  return (
    <div className='container-fluid devops-container  services pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>Devops</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 services-text-container'>

        <div className='col-lg-6 services-text mt-3'>
          <ul className='fs-5'>
            <li className='p-3'>
              DevOps is the combination of cultural philosophies, practices, and tools that increases an organization's ability to deliver applications and services at high velocity.
            </li>
            <li className='p-3'>
              Evolving and improving products at a faster pase than organizations using traditional software development and infrastructure management processes.
            </li>

          </ul>
        </div>
        <div className='col-lg-6 devopsImg col-sm-7 shadow bg-light rounded-3'>
          <img src={devops} alt='devopsimage' height="350" width="100%" className='shadow-light rounded-4' />
        </div>
      </div>
    </div>
  )
}

export default Devops
