import React from 'react';
import aws from '../images/aws.webp'



function Aws() {
  return (
    <div className='container-fluid aws-container technologies pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>AWS</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 technologies-text'>

        <div className='col-lg-6 Aws '>
          <ul className='fs-5'>
            <li className='p-3'>
              Amazon Web Services, inc. is a subsidiary
              of Amazon that provides on-demand cloud computing
              platforms and APIs to individuals, companies, and governments
              on a metered, pay-as-you-go basis. oftentimes, clients will use
              this in combination with autoscroling.
            </li>
          </ul>
        </div>
        <div className='col-lg-6 AwsImg col-sm-7'>
          <img src={aws} alt='Awsimage' height="350" width="100%" className='shadow-light rounded-3' />
        </div>
      </div>
    </div>
  )
}

export default Aws
