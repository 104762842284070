import React from 'react'
import aboutimg from '../components/images/about.webp';
import './Style.css';
//import {Link} from 'react-router-dom'

function AboutUs() {
  return (
    <div className='container-fluid About-container pb-3 pt-2'>
      <div className='row  text-center ' >
        <h3 className='mt-3 heading'><u>About Us</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2'>
        <div className='col-lg-6 AboutImg col-sm-7 '>
          <img src={aboutimg} alt='aboutimage' height="350" width="100%" className='shadow-light rounded-2' />
        </div>
        <div className='col-lg-6 About-text'>
          <ul className='fs-5'>
            <li className='p-3'>
              Wamika Technologies is an IT Services and Technologies
              enterprise with prolific backend engineering teams
              that serves customers accross the globe.
            </li>
            <li className='p-3'>
              We deliver a wide range of IT services accross
              varies industries & continue to focus on innovative
              products, services and solutions to assist our clients.
            </li>
            <li className='p-3'>
              We work closely with Technology innovaters and ensure that the team creates  <q>Effective Software</q> based on thorough understanding of underlying Technologies.
            </li>
          </ul>
          {/* <Link to='/contact1' className='btn btn-primary'>Contact ref</Link>  */}
          {/* <Link to='/contact' className='btn btn-primary'>Contact Us</Link> */}
        </div>
      </div>
    </div>
  )
}

export default AboutUs
