import React from 'react'
import salesforce from '../images/salesforce.jpg';

function SalesForce() {
  return (
    <div className='container-fluid salesforce-container technologies pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>SalesForce</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 technologies-text'>

        <div className='col-lg-6 Salesforce '>
          <ul className='fs-5'>
            <li className='p-3'>
              SalesForce is the customer company.
              We make cloud-based software designed
              to help businesses connect to their customers
              in a whole new way, so they can find more prospects,
              close more deals, and wow customers with amazing service.
            </li>
            <li className='p-3'>
              Customer 360, our complete suite of products, unites your
              sales, service, marketing, commerce, and IT teams with a
              single, shared view of customer information, so that your
              company can become a customer company too.
            </li>
          </ul>
        </div>
        <div className='col-lg-6 SalesforceImg col-sm-7 '>
          <img src={salesforce} alt='SalesForceimage' height="350" width="100%" className='shadow rounded-3 ' />
        </div>
      </div>
    </div>
  )
}

export default SalesForce
