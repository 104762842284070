import React from 'react';
import logo1 from '../components/images/logo1.png';
import { Link } from 'react-router-dom';
import './Style.css'

function NavBar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light  d-flex flex-row justify-content-center navigation  navbar-container">
        <div className="container-fluid nav-container">
          <div className='nav-logo  col-1 justify-content-start'>
            <Link to="/"><img src={logo1} alt='nav-logo'  className='logo'/></Link>
          </div>
          <div className='p-3 '>
          <Link className="navbar-brand  title nav-Heading  text-light fs-4 " to="/" style={{fontFamily:'Recharge'}}>WAMIKA TECHNOLOGIES</Link>
          </div>
          <div className='p-2'>
          <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          </div>
          <div className="collapse navbar-collapse justify-content-end " id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ml-5 list-container">
              
              <li className="nav-item dropdown ml-5 ">
                <Link className="nav-link dropdown-toggle fs-6 text-info service" to="/services" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                  Services
                </Link>
                <ul className="dropdown-menu  bg-secondary text-white col-3" aria-labelledby="navbarDropdown" >
                  <li><Link className="dropdown-item text-white dropdown-items " to="/services/webdevelopment">Web Development</Link></li>
                  <li><hr className="dropdown-divider"  /></li>
                  <li><Link className="dropdown-item text-white dropdown-items" to="/services/devops">Devops</Link></li>
                  <li><hr className="dropdown-divider"  /></li>
                  <li><Link className="dropdown-item text-white dropdown-items" to="/services/qa">Quality Assurance</Link></li>
                  <li><hr className="dropdown-divider"  /></li>
                  <li><Link className="dropdown-item text-white dropdown-items" to="/services/modernization">Modernization</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><Link className="dropdown-item text-white dropdown-items" to="/services/automation">Automation</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><Link className="dropdown-item text-white dropdown-items" to="/services/staffing">IT Staffing</Link></li>
                  
                </ul>
              </li>

              <li className="nav-item dropdown ml-5">
                <Link className="nav-link dropdown-toggle fs-6 text-info technologies" to="/technologies" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Technologies
                </Link>
                <ul className="dropdown-menu  bg-secondary text-white col-2" width="20" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item dropdown-items text-white view overlay zoom" to="/technologies/salesforce">Salesforce</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><Link className="dropdown-item dropdown-items text-white " to="/technologies/servicenow">ServiceNow</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><Link className="dropdown-item dropdown-items text-white" to="/technologies/bigcommerce">Big commerce</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><Link className="dropdown-item dropdown-items text-white" to="/technologies/splunk">Splunk</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><Link className="dropdown-item dropdown-items text-white" to="/technologies/azure">Azure</Link></li>
                  <li><hr className="dropdown-divider"  /></li>
                  <li><Link className="dropdown-item dropdown-items text-white" to="/technologies/aws">AWS</Link></li>
                </ul>
              </li>
              <li className="nav-item ml-5">
                <Link className="nav-link active ml-1 fs-6 text-info about-head" aria-current="page" to="/about">About Us</Link>
              </li>
              <li className="nav-item ml-5">
                <Link className="nav-link active ml-1 fs-6 text-info contact-head" aria-current="page" to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </>
  );
}

export default NavBar;