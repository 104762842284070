import React from 'react';
import modernization from '../images/modernization.jpg'
import './ServicesStyle.css';


function Modernization() {
  return (
    <div className='container-fluid modernization-container services pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>Modernization</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 mt-3 services-text-container mb-2'>

        <div className='col-lg-6 services-text'>
          <ul className='fs-5'>
            <li className='p-3'>
              Modernization refers to a model of a progressive transition from a "Pre-Modern" or "Traditional" to a "modern" society.
            </li>
            <li className='p-3'>
              Modernization Theory suggests that traditional societies will deveop as they adopt more modern practices
            </li>

          </ul>
        </div>
        <div className='col-lg-6 modernizationImg col-sm-7  '>
          <img src={modernization} alt='modernizationimage' height="350" width="100%" className='shadow-light rounded-3' />
        </div>
      </div>
    </div>
  )
}

export default Modernization
