import React from 'react'
import splunk from '../images/splunk.jpg';


function Splunk() {
  return (
    <div className='container-fluid splunk-container technologies pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>Splunk</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 technologies-text'>

        <div className='col-lg-6 Splunk '>
          <ul className='fs-5'>
            <li className='p-3'>
              Splunk Inc. is an American software company
              based in San Fransico, California, that produces
              software for searching, monitoring, and analyzing
              machine-generated data via a web-style interface.
            </li>

          </ul>
        </div>
        <div className='col-lg-6 SplunkImg col-sm-7 '>
          <img src={splunk} alt='Splunkimage' height="350" width="100%" className='shadow rounded-3 ' />
        </div>
      </div>
    </div>
  )
}

export default Splunk
