import React from 'react'
//import NavBar from './NavBar';
import img1 from '../components/images/bgimg.jpg';
//import { Link } from 'react-router-dom';
import service from '../components/images/services.jpg';
import technologies from '../components/images/technologies.png';

function Header() {
  return (
    <div>

      <img src={img1} alt='homeimg' width='100%' className='' />
      <div className='container-fluid About-container '>
        <div className='row  text-center ' >
          <h3 className='mt-3 heading'><u>Services</u></h3>
        </div>
        <div className='row d-flex flex-row justify-content-center p-4'>
          <div className='col-lg-6 AboutImg col-sm-7 '>
            <img src={service} alt='aboutimage' height="350" width="100%" className='shadow-light rounded-2' />
          </div>
          <div className='col-lg-6 About-text'>
            <ul className='fs-5'>
              <li className='m-3'>
                We are rapidly approaching a time when all things
                in society will be connected through digital touchpoints
                and services, where data will be utilized to deliver insights
                and benefits well beyond the boundaries of industries and companies.
              </li>
              <li className='m-3'>
                In this new connected world, a strong and resilient digital
                infrastructure foundation will be required for close collaboration.
              </li>

            </ul>

          </div>
        </div>
      </div>
      <div className='container-fluid salesforce-container technologies'>
        <div className='row  text-center' >
          <h3 className='heading mt-3'><u>Technologies</u></h3>
        </div>
        <div className='row d-flex flex-row justify-content-center p-4 technologies-text'>

          <div className='col-lg-6 Salesforce '>
            <ul className='fs-5'>
              <li className='m-3'>
                The world is changing fast. To stay competitive,
                businesses must be ready to embrace IT transformation
                in their operations, tap into business data in new ways,
                and rethink IT system management.
              </li>
              <li className='m-3'>
                The future of IT brings with it unprecedented
                opportunities for businesses that are well prepared.
              </li>
            </ul>
          </div>
          <div className='col-lg-6 SalesforceImg col-sm-7 '>
            <img src={technologies} alt='SalesForceimage' height="350" width="100%" className='shadow rounded-3 ' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
