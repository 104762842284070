import React from "react";
import contact from "../components/images/contact.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";

function ContactUs() {
  // /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/
  const phoneRegExp = /^[1-9]{1}[0-9]{9}$/;
  const fnameRegExp = /^[A-Za-z0-9]+$/;
  const lnameRegExp = /^[A-Za-z0-9]+$/;
  const emailRegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const CountryRegExp = /^(\+\d{1,3})$/;
  const initialValues = {
    FirstName: "",
    LastName: "",
    Email: "",
    Country: "",
    Phone: "",
    BusinessNeed: "",
  };
  const validationSchema = Yup.object().shape({
    FirstName: Yup.string().matches(fnameRegExp).max(50).required("Required"),
    LastName: Yup.string().matches(lnameRegExp).max(50).required("Required"),
    Email: Yup.string().email().matches(emailRegExp).required("Required"),
    Country: Yup.string().matches(CountryRegExp).required("Required"),
    Phone: Yup.string().matches(phoneRegExp).required("Required"),
    BusinessNeed: Yup.string().max(1000).required("Requied"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      alert(JSON.stringify(values, null, 2));
      formik.values.preventDefault();
    },
  });

  return (
    <div className="container-fluid text-center pb-3 pt-2 Contact-container">
      <div className="row  text-center mt-3">
        <h3 className="text-center heading">
          <u>Contact Us</u>
        </h3>
      </div>
      <div className="row mt-4  d-flex justify-content-between container-fluid  text-center p-2">
        <div className="col-lg-5 text-center d-flex ml-3">
          <img
            src={contact}
            alt="contactimage"
            height="350"
            width="100%"
            className="shadow-light rounded-2 text-center"
          />
        </div>
        <div className="col-lg-4 text-center form-container text-white ml-3  d-flex p-2 mt-2 rounded-3 justify-content-center">
          <form onSubmit={formik.handleSubmit} className=" text-center mt-1">
            <div className="form-group d-flex flex-row ">
              <label htmlFor="FirstName ">
                First Name{" "}
                <span className="fs-6" style={{ color: "red" }}>
                  {" "}
                  *{" "}
                </span>
              </label>
              <div className="ml-4">
                <input
                  id="FirstName"
                  name="FirstName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.FirstName}
                  placeholder="first name"
                  className="text-center"
                />
                {formik.errors.FirstName && formik.touched.FirstName ? (
                  <div className="text-danger">{formik.errors.FirstName}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group d-flex flex-row">
              <label htmlFor="LastName mr-2">
                Last Name
                <span className="fs-6" style={{ color: "red" }}>
                  {" "}
                  *{" "}
                </span>
              </label>
              <div className="ml-4">
                <input
                  id="LastName"
                  name="LastName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.LastName}
                  placeholder="last name"
                  className="text-center"
                />
                {formik.errors.LastName && formik.touched.LastName ? (
                  <div className="text-danger">{formik.errors.LastName}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group d-flex flex-row">
              <label htmlFor="Email">
                Email Address
                <span className="fs-6" style={{ color: "red" }}>
                  {" "}
                  *{" "}
                </span>
              </label>
              <div className="ml-1">
                <input
                  id="Email"
                  name="Email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.Email}
                  placeholder="email"
                  className="text-center"
                />
                {formik.errors.Email && formik.touched.Email ? (
                  <div className="text-danger">{formik.errors.Email}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group d-flex flex-row">
              <label htmlFor="Country">
                Phone
                <span className="fs-6" style={{ color: "red" }}>
                  {" "}
                  *{" "}
                </span>{" "}
              </label>
              <div className="ml-5">
                <input
                  id="Country"
                  name="Country"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.Country}
                  style={{ width: "30px" }}
                  placeholder="+"
                  className="text-center ml-1"
                />
                <input
                  id="Phone"
                  name="Phone"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.Phone}
                  style={{ width: "160px" }}
                  placeholder="Phone number"
                  className="text-center"
                />
                <br />
                {formik.errors.Country && formik.touched.Country ? (
                  <span className="text-danger mr-5">
                    {formik.errors.Country}
                  </span>
                ) : null}
                {formik.errors.Phone && formik.touched.Phone ? (
                  <span className="text-danger">{formik.errors.Phone}</span>
                ) : null}
              </div>
            </div>

            <div className="form-group d-flex flex-row">
              <label htmlFor="BusinessNeed">
                Business Need
                <span className="fs-6" style={{ color: "red" }}>
                  {" "}
                  *{" "}
                </span>
              </label>
              <div className="ml-1">
                <textarea
                  id="BusinessNeed"
                  name="BusinessNeed"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.BusinessNeed}
                  placeholder="Business need"
                  className="text-center"
                  cols={23}
                  rows={3}
                />
                {formik.errors.BusinessNeed && formik.touched.BusinessNeed ? (
                  <div className="text-danger">
                    {formik.errors.BusinessNeed}
                  </div>
                ) : null}
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
