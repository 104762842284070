import React from 'react'
import servicenow from '../images/servicenow.jpg';

function ServiceNow() {
  return (
    <div className='container-fluid servicenow-container technologies pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>ServiceNow</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 technologies-text'>

        <div className='col-lg-6 ServiceNow '>
          <ul className='fs-5'>
            <li className='p-3'>
              ServiceNow, Inc. is an american software
              company based in santa clara, california that
              develops a cloud computing platform to help companies
              manage digital workflows for enterprose operations.
            </li>

          </ul>
        </div>
        <div className='col-lg-6 ServicenowImg col-sm-7 '>
          <img src={servicenow} alt='Servicenowimage' height="350" width="100%" className='shadow rounded-3 ' />
        </div>
      </div>
    </div>
  )
}

export default ServiceNow
