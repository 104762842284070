import React from 'react';
import azure from '../images/azure.jpg'

function Azure() {
  return (
    <div className='container-fluid Azure-container  technologies pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>Azure</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 technologies-text'>

        <div className='col-lg-6 Azure '>
          <ul className='fs-5'>
            <li className='p-3'>
              Microsoft Azure, often referred to as Azure,
              is a cloud computing platform run by Microsoft,
              which offers access, management and development
              of applications and services through global data centers.
            </li>
          </ul>
        </div>
        <div className='col-lg-6 AzureImg col-sm-7 '>
          <img src={azure} alt='Azureimage' height="350" width="100%" className='shadow rounded-3 ' />
        </div>
      </div>
    </div>
  )
}

export default Azure
