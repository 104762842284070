import React from 'react';
import contact from '../components/images/contact.jpg';
import { useFormik } from 'formik'
import * as Yup from 'yup';
import 'yup-phone';


function Contact() {

    const initialValues = {
        FirstName: '',
        LastName: '',
        Email: '',
        Country: '',
        Phone: '',
        BusinessNeed: ''
    }
    const validationSchema = Yup.object({
        FirstName: Yup.string().max(50).required("Required"),
        LastName: Yup.string().max(50).required("Required"),
        Email: Yup.string().email().required("Required"),
        Country: Yup.string().max(4, "only 4 chars are allowed including +").required("Required"),
        Phone: Yup.string().max(10, 'maximum 10 chars are allowed only').min(10).required('Required'),
        BusinessNeed: Yup.string().max(1000).required("Requied")

    })
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {

            console.log(values)
            alert(JSON.stringify(values, null, 2));
        },
    })

    return (
        <div className='container-fluid text-center p-3 Contact-container'>
            <div className='row  text-center mt-3' >
                <h3 className='text-center heading'><u>Contact Us</u></h3>
            </div>
            <div className='row mt-4  d-flex justify-content-between container-fluid  text-center p-2'>
                <div className='col-lg-5 text-center d-flex ml-3'>
                    <img src={contact} alt='contactimage' height="350" width="100%" className='shadow-light rounded-2 text-center' />
                </div>
                <div className='col-lg-4 text-center form-container text-white ml-3  d-flex p-3 mt-2 rounded-3 justify-content-center' >
                    <form onSubmit={formik.handleSubmit} className=' text-center'>
                        <div className='form-group d-flex flex-row '>
                            <label htmlFor="FirstName ">First Name<sup className='text-danger'>*</sup>:</label>
                            <div className='ml-4'>
                                <input
                                    id="FirstName"
                                    name="FirstName"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.FirstName}
                                    placeholder='first name'
                                    className='text-center'
                                />
                                {formik.errors.FirstName ?
                                    <div className='text-danger'>{formik.errors.FirstName}</div> : null}
                            </div>
                        </div>
                        <div className='form-group d-flex flex-row'>
                            <label htmlFor="LastName mr-2">Last Name:</label>
                            <div className='ml-4'>
                                <input
                                    id="LastName"
                                    name="LastName"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.LastName}
                                    placeholder='last name'
                                    className='text-center'
                                />
                                {formik.errors.LastName ?
                                    <div className='text-danger'>{formik.errors.LastName}</div> : null}
                            </div>
                        </div>
                        <div className='form-group d-flex flex-row'>
                            <label htmlFor="Email">Email Address:</label>
                            <div className='ml-1'>
                                <input
                                    id="Email"
                                    name="Email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.Email}
                                    placeholder='email'
                                    className='text-center'
                                />
                                {formik.errors.Email ?
                                    <div className='text-danger'>{formik.errors.Email}</div> : null}
                            </div>
                        </div>
                        <div className='form-group d-flex flex-row'>
                            <label htmlFor="Country">Phone: </label>
                            <div className='ml-5'>
                                <input
                                    id="Country"
                                    name="Country"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.Country}
                                    style={{ width: "30px" }}
                                    placeholder='+'
                                    className='text-center ml-1'
                                />
                                <input
                                    id="Phone"
                                    name="Phone"
                                    type="number"
                                    onChange={formik.handleChange}
                                    value={formik.values.Phone}
                                    style={{ width: "160px" }}
                                    placeholder='Phone number'
                                    className='text-center'
                                />
                                {formik.errors.Phone ?
                                    <div className='text-danger'>{formik.errors.Phone}</div> : null}
                            </div>
                        </div>

                        <div className='form-group d-flex flex-row'>
                            <label htmlFor="BusinessNeed">Business Need:</label>
                            <div className='ml-1'>
                                <textarea
                                    id="BusinessNeed"
                                    name="BusinessNeed"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.BusinessNeed}
                                    placeholder='Business need'
                                    className='text-center'
                                    cols={23}
                                    rows={3}

                                />
                                {formik.errors.BusinessNeed ?
                                    <div className='text-danger'>{formik.errors.BusinessNeed}</div> : null}
                            </div>
                        </div>
                        <button type="submit" className='btn btn-primary'>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact

