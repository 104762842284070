
import './App.css';
import AboutUs from './components/AboutUs';
import Header from './components/Header';
import Footer from './components/Footer';
import Automation from './components/services/Automation';
import Devops from './components/services/Devops';
import Modernization from './components/services/Modernization';
import QualityAssurance from './components/services/QualityAssurance';
import WebDevelopement from './components/services/WebDevelopement';
import ITStaffing from './components/services/ITStaffing';


import Aws from './components/technologies/Aws';
import Azure from './components/technologies/Azure';
import BigCommerce from './components/technologies/BigCommerce';
import SalesForce from './components/technologies/SalesForce';
import ServiceNow from './components/technologies/ServiceNow';
import Splunk from './components/technologies/Splunk';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import ContactUs from './components/ContactUs';
import Contact from './components/Contact';

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route exact path='/about' Component={AboutUs} />
        <Route exact path='/contact' Component={ContactUs} />
        <Route exact path='/contact1' Component={Contact} />
        <Route exact path='/' Component={Header} />
        <Route exact path='/services/automation' Component={Automation} />
        <Route exact path='/services/devops' Component={Devops} />
        <Route exact path='/services/modernization' Component={Modernization} />
        <Route exact path='/services/qa' Component={QualityAssurance} />
        <Route exact path='/services/webdevelopment' Component={WebDevelopement} />
        <Route exact path='/services/staffing' Component={ITStaffing} />

        <Route exact path='/technologies/aws' Component={Aws} />
        <Route exact path='/technologies/Azure' Component={Azure} />
        <Route exact path='/technologies/bigcommerce' Component={BigCommerce} />
        <Route exact path='/technologies/salesforce' Component={SalesForce} />
        <Route exact path='/technologies/servicenow' Component={ServiceNow} />
        <Route exact path='/technologies/splunk' Component={Splunk} />
      </Routes>
      <Footer />
    </BrowserRouter>

  );

}

export default App;
