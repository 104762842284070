import React from 'react';
import webdevelopment from '../images/web-development.jpg'
import './ServicesStyle.css';

function WebDevelopement() {
  return (
    <div className='container-fluid webdev-container services pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>Web Development</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 services-text-container'>

        <div className='col-lg-6 webdev mt-3 services-text'>
          <ul className='fs-5'>
            <li className='p-3'>
              Web Development is the work involved in developing a website in the internet (World Wide Web) or an internet (a private network).
            </li>
            <li className='p-3'>
              Web Developement can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services.
            </li>

          </ul>
        </div>
        <div className='col-lg-6 AboutImg col-sm-7 '>
          <img src={webdevelopment} alt='aboutimage' height="350" width="100%" className='shadow-light rounded-3' />
        </div>
      </div>
    </div>
  )
}

export default WebDevelopement
