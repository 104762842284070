import React from 'react';
import staffing from '../images/staffing.jpg'
import './ServicesStyle.css';


function ITStaffing() {
  return (
    <div className='container-fluid staffing-container  services pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>IT Staffing</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 services-text-container'>

        <div className='col-lg-6 services-text mt-3'>
          <ul className='fs-5'>
            <li className='p-3'>
            IT staffing is the act of finding job candidates with the
             specialized skills needed to fill technology roles on your team.
             
            </li>
            <li className='p-3'>
            The primary objective of the IT staffing process is to find competent 
            candidates to fill the different job roles in the IT company. 
            </li>
            <li className='p-3'>
            It is a systematic implementation process of the human resources plan,
             which involves finding or attracting candidates, evaluation, selection,
              recruitment and appraisal.
            </li>

          </ul>
        </div>
        <div className='col-lg-6 devopsImg col-sm-7   rounded-3 mt-2'>
          <img src={staffing} alt='staffingImage' height="350" width="100%" className='shadow-light rounded-4' />
        </div>
      </div>
    </div>
  )
}

export default ITStaffing
