import React from 'react';
import qa from '../images/qa.jpg'
import './ServicesStyle.css';

function QualityAssurance() {
  return (
    <div className='container-fluid Qa-container  services pb-3 pt-2'>
      <div className='row  text-center' >
        <h3 className='heading mt-3'><u>Quality Assurance</u></h3>
      </div>
      <div className='row d-flex flex-row justify-content-center p-2 services-text-container'>

        <div className='col-lg-6 Quality mt-3 services-text'>
          <ul className='fs-5'>
            <li className='p-3'>
              Quality Assurance (QA) is any systematic process of determining whether a product or service meets specified requirements.
            </li>
            <li className='p-3'>
              QA establishes and maintains set requirements for developing or manufacturing reliable products.
            </li>

          </ul>
        </div>
        <div className='col-lg-6 QaImg col-sm-7  rounded-3'>
          <img src={qa} alt='QAimage' height="350" width="100%" className='shadow-light rounded-3' />
        </div>
      </div>
    </div>
  )
}

export default QualityAssurance
